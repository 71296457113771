export const AUTH_BASE_URL = `/Auth`;
export const USER_BASE_URL = `/User`;

export const USER_ROLES = `${AUTH_BASE_URL}/Roles`;
export const GET_USER_BY_ACCESSTOKEN = `${AUTH_BASE_URL}/verify_token`;
export const LOGIN = `${AUTH_BASE_URL}/Login`;
export const REGISTER = `${AUTH_BASE_URL}/RegisterUser`;
export const FORGOT_PASSWORD = `${AUTH_BASE_URL}/ForgotPassword`;
export const RESET_PASSWORD = `${AUTH_BASE_URL}/ResetPassword`;

export const CHANGE_PASSWORD = `${USER_BASE_URL}/ChangePassword`;
export const USER_LIST = `${USER_BASE_URL}/GetAllUsersDetails`;
export const USER_DETAILS = `${USER_BASE_URL}/GetUserDetails`;
export const EDIT_USER_DETAILS = `${USER_BASE_URL}/EditUserDetails`;
export const ACTIVATE_DEACTIVATE_USER = `${USER_BASE_URL}/ActiveDeactivateUserAccount`;
export const GET_USER_DETAIL_BY_ID = `${USER_BASE_URL}/GetUserDetailsById`;

// Masters
export const GET_ALL_DISTRICT = `District/GetAllDistrict`;
export const GET_ALL_DEPARTMENT = `Department/GetAllDepartment`;
export const GET_ALL_BANK = `Bank/GetAllBank`;
export const GET_ALL_BANK_BRANCH = `BankBranch/GetAllBankBranch`;
export const GET_ALL_BANK_OFFICER = `BankOfficer/GetAllBankOfficer`;
export const GET_ALL_ASSOCIATE_ADVOCATE = `AssociateAdvocate/GetAllAssociateAdvocate`;
export const GET_ALL_EXECUTER_NAME = `ExecuterName/GetAllExecuterName`;
export const GET_ALL_EXECUTING_OFFICER_DESIGNATION = "/ExecutingOfficerDesignation/GetAllExecutingOfficerDesignation";
export const GET_ALL_FORUM = "/Forum/GetAllForum";
export const GET_ALL_JUDGE_NAME = "JudgeName/GetAllJudgeName";
export const GET_ALL_OUR_ADVOCATE = "/OurAdvocate/GetAllOurAdvocate";
export const GET_ALL_TALUKA = "/Taluka/GetAllTaluka";
export const GET_ALL_PRODUCT = "/Product/GetAllProduct";
export const GET_ALL_STAGE = "/Stage/GetAllStage";
export const GET_ALL_DESGINATION = "/Designation/GetAllDesignation";
export const GET_ALL_CASE_TYPE = "/CaseType/GetAllCaseType";
export const GET_ALL_CASE_CATEGORY = "/CaseCategory/GetAllCaseCategory";
export const GET_ALL_DISPOSAL = "/DisposalMode/GetAllDisposalMode";
export const GET_ALL_OPPOSITE_ADVOCATE = "/OppositeAdvocate/GetAllOppositeAdvocate";

export const GET_BY_ID_DISTRICT = `District/GetDistrictById`;
export const GET_BY_ID_DEPARTMENT = `Department/GetDepartmentById`;
export const GET_BY_ID_BANK = `Bank/GetBankById`;
export const GET_BY_ID_BANK_BRANCH = `BankBranch/GetBankBranchById`;
export const GET_BY_ID_BANK_OFFICER = `BankOfficer/GetBankOfficerById`;
export const GET_BY_ID_ASSOCIATE_ADVOCATE = `AssociateAdvocate/GetAssociateAdvocateById`;
export const GET_BY_ID_EXECUTER_NAME = `ExecuterName/GetExecuterNameById`;
export const GET_BY_ID_EXECUTING_OFFICER_DESIGNATION = "/ExecutingOfficerDesignation/GetExecutingOfficerDesignationById";
export const GET_BY_ID_FORUM = "/Forum/GetForumById";
export const GET_BY_ID_JUDGE_NAME = "JudgeName/GetJudgeNameById";
export const GET_BY_ID_OUR_ADVOCATE = "/OurAdvocate/GetOurAdvocateById";
export const GET_BY_ID_TALUKA = "/Taluka/GetTalukaById";

export const POST_DISTRICT = "/District/AddDistrict";
export const POST_DEPARTMENT = "Department/AddDepartment";
export const POST_BANK = "/Bank/AddBank";
export const POST_BANK_BRANCH = "/BankBranch/AddBankBranch";
export const POST_BANK_OFFICER = "/BankOfficer/AddBankOfficer";
export const POST_ASSOCIATE_ADVOCATE = "/AssociateAdvocate/AddAssociateAdvocate";
export const POST_EXECUTER_NAME = "/ExecuterName/AddExecuterName";
export const POST_EXECUTING_OFFICER_OFFICER_DESIGNATION = "/ExecutingOfficerDesignation/AddExecutingOfficerDesignation";
export const POST_FORUM = "/Forum/AddForum";
export const POST_JUDGE_NAME = "/JudgeName/AddJudgeName";
export const POST_OUR_ADVOCATE = "/OurAdvocate/AddOurAdvocate";
export const POST_TALUKA = "/Taluka/AddTaluka";
export const POST_PRODUCT = "/Product/AddProduct";
export const POST_STAGE = "/Stage/AddStage";
export const POST_DESIGNATION = "/Designation/AddDesignation";
export const POST_CASE_CATEGORY = "/CaseCategory/AddCaseCategory";
export const POST_DISPOSAL = "/DisposalMode/AddDisposalMode";
export const POST_CASE_TYPE = "/CaseType/AddCaseType";
export const POST_OPPOSITE_ADVOCATE = "/OppositeAdvocate/AddOppositeAdvocate";

export const ACTIVE_DEACTIVE_DISTRICT = "/District/ActiveDeactivateDistrict";
export const ACTIVE_DEACTIVE_DEPARTMENT = "Department/ActiveDeactivateDepartment";
export const ACTIVE_DEACTIVE_BANK = "/Bank/ActiveDeactivateBank";
export const ACTIVE_DEACTIVE_BANK_BRANCH = "/BankBranch/ActiveDeactivateBankBranch";
export const ACTIVE_DEACTIVE_BANK_OFFICER = "/BankOfficer/ActiveDeactivateBankOfficer";
export const ACTIVE_DEACTIVE_ASSOCIATE_ADVOCATE = "AssociateAdvocate/ActiveDeactivateAssociateAdvocate";
export const ACTIVE_DEACTIVE_EXECUTER_NAME = "/ExecuterName/ActiveDeactivateExecuterName";
export const ACTIVE_DEACTIVE_EXECUTING_OFFICER_OFFICER_DESIGNATION = "/ExecutingOfficerDesignation/ActiveDeactivateExecutingOfficerDesignation";
export const ACTIVE_DEACTIVE_FORUM = "/Forum/ActiveDeactivateForum";
export const ACTIVE_DEACTIVE_JUDGE_NAME = "/JudgeName/ActiveDeactivateJudgeName";
export const ACTIVE_DEACTIVE_OUR_ADVOCATE = "/OurAdvocate/ActiveDeactivateOurAdvocate";
export const ACTIVE_DEACTIVE_TALUKA = "/Taluka/ActiveDeactivateTaluka";
export const ACTIVE_DEACTIVE_PRODUCT = "/Product/ActiveDeactivateProduct";
export const ACTIVE_DEACTIVE_STAGE = "/Stage/ActiveDeactivateStage";
export const ACTIVE_DEACTIVE_OPPOSITE_ADVOCATE = "/OppositeAdvocate/ActiveDeactivateOppositeAdvocate";
export const ACTIVE_DEACTIVE_CASE_TYPE = "/CaseType/ActiveDeactivateCaseType";
export const ACTIVE_DEACTIVE_DISPOSAL = "/DisposalMode/ActiveDeactivateDisposalMode";
export const ACTIVE_DEACTIVE_CASE_CATEGORY = "/CaseCategory/ActiveDeactivateCaseCategory";
export const ACTIVE_DEACTIVE_DESIGNATION = "/Designation/ActiveDeactivateDesignation";

export const GET_DISTRICT_BY_ID = `District/GetDistrictById`;
export const GET_DEPARTMENT_BY_ID = `Department/GetDepartmentById`;
export const GET_BANK_BY_ID = `Bank/GetBankById`;
export const GET_BANK_BRANCH_BY_ID = `BankBranch/GetBankBranchById`;
export const GET_BANK_OFFICER_BY_ID = `BankOfficer/GetBankOfficerById`;
export const GET_ASSOCIATE_ADVOCATE_BY_ID = `AssociateAdvocate/GetAssociateAdvocateById`;
export const GET_EXECUTER_NAME_BY_ID = `ExecuterName/GetExecuterNameById`;
export const GET_EXECUTING_OFFICER_DESIGNATION_BY_ID = "/ExecutingOfficerDesignation/GetExecutingOfficerDesignationById";
export const GET_FORUM_BY_ID = "/Forum/GetForumById";
export const GET_JUDGE_NAME_BY_ID = "JudgeName/GetJudgeNameById";
export const GET_OUR_ADVOCATE_BY_ID = "/OurAdvocate/GetOurAdvocateById";
export const GET_TALUKA_BY_ID = "/Taluka/GetTalukaById";
export const GET_PRODUCT_BY_ID = "/Product/GetProductById";
export const GET_STAGE_BY_ID = "/Stage/GetStageById";
export const GET_DESIGNATION_BY_ID = "/Designation/GetDesignationById";
export const GET_OPPOSITE_ADVOCATE_BY_ID = "/OppositeAdvocate/GetOppositeAdvocateById";
export const GET_DISPOSAL_BY_ID = "/DisposalMode/GetDisposalModeById";
export const GET_CASE_TYPE_BY_ID = "/CaseType/GetCaseTypeById";
export const GET_CASE_CATEGORY_BY_ID = "/CaseCategory/GetCaseCategoryById";

export const UPDATE_DISTRICT_BY_ID = `/District/EditDistrict`;
export const UPDATE_DEPARTMENT_BY_ID = `/Department/EditDepartment`;
export const UPDATE_BANK_BY_ID = `/Bank/EditBank`;
export const UPDATE_BANK_BRANCH_BY_ID = `/BankBranch/EditBankBranch`;
export const UPDATE_BANK_OFFICER_BY_ID = `/BankOfficer/EditBankOfficer`;
export const UPDATE_ASSOCIATE_ADVOCATE_BY_ID = `/AssociateAdvocate/EditAssociateAdvocate`;
export const UPDATE_EXECUTER_NAME_BY_ID = `/ExecuterName/EditExecuterName`;
export const UPDATE_EXECUTING_OFFICER_DESIGNATION_BY_ID = "/ExecutingOfficerDesignation/EditExecutingOfficerDesignation";
export const UPDATE_FORUM_BY_ID = "/Forum/EditForum";
export const UPDATE_JUDGE_NAME_BY_ID = "/JudgeName/EditJudgeName";
export const UPDATE_OUR_ADVOCATE_BY_ID = "/OurAdvocate/EditOurAdvocate";
export const UPDATE_TALUKA_BY_ID = "/Taluka/EditTaluka";
export const UPDATE_STAGE_BY_ID = "/Stage/EditStage";
export const UPDATE_PRODUCT_BY_ID = "/Product/EditProduct";
export const UPDATE_OPPOSITE_ADVOCATE_BY_ID = "/OppositeAdvocate/EditOppositeAdvocate";
export const UPDATE_DISPOSAL_BY_ID = "/DisposalMode/EditDisposalMode";
export const UPDATE_CASE_TYPE_BY_ID = "/CaseType/EditCaseType";
export const UPDATE_CASE_CATEGORY_BY_ID = "/CaseCategory/EditCaseCategory";
export const UPDATE_DESIGNATION_BY_ID = "/Designation/EditDesignation";

// dropdown
export const GET_BANK_BRANCH_BY_BANK_ID = "/BankBranch/GetBankBranchByBankId";
export const GET_TALUKA_BY_DISTRICT_ID = "/Taluka/GetTalukaByDistrict";
export const GET_BANK_FOR_DROPDOWN = "/Bank/GetBanksForDropdown";
export const GET_DISTRICT_FOR_DROPDOWN = "/District/GetAllDistrictForDropDown";
export const GET_FORUM_FOR_DROPDOWN = "/Forum/GetAllForumForDropDown";
export const GET_STAGE_FOR_DROPDOWN = "/Stage/GetStagesForDropdown";
export const GET_JUDGE_FOR_DROPDOWN = "/JudgeName/GetAllJudgeNameByForumId";
export const GET_BANK_OFFCIER_FOR_DROPDOWN = "BankOfficer/GetBankOfficerByBankBranchId";
export const GET_PRODUCT_BY_BANK_FOR_DROPDOWN = "/Product/GetProductsByBank";
export const GET_DESIGNATION_FOR_DROPDOWN = "/Designation/GetDesignationsForDropDown";
export const GET_CASE_CATEGORY_FOR_DROPDOWN = "/CaseCategory/GetCaseCategorysForDropDown";
export const GET_EXE_OFF_DESIGNATION_FOR_DROPDOWN = "/ExecutingOfficerDesignation/GetAllExecutingOfficerDesignationForDropDown";
export const GET_BANK_OFFICER_BY_BANK = '/BankOfficer/GetBankOfficerByBank'
export const GET_JUDGE_BY_TALUKA = '/JudgeName/GetAllJudgeNameByTaluka';
export const GET_CASE_TYPE_BY_CASE_CAT = '/CaseType/GetCaseTypeByCaseCategory'


// Case
export const ADD_CASE = "/Case/AddCase";
export const GET_ALL_CASE = "/Case/GetAllCase";
export const GET_CASE_BY_ID = "Case/GetCaseById";
export const EDIT_CASE = "Case/EditCase";
export const ACIVATE_DEACTIVE_CASE = "Case/ActiveDeactivateCase";
