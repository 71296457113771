import { KTSVG } from "../../_metronic/helpers";

export const REQUIRED = "Required";
export const NO_DOT_ALLOWED = "No dots allowed";
// export const ADVOCATE = "Advocate";
export const SUBMIT = "Submit";
export const NEXT = (
  <>
    <span>Next </span>
    <KTSVG path="/media/icons/duotune/arrows/arr064.svg" className="svg-icon-4 ms-1 m-0" />
  </>
);
export const BACK = (
  <>
    <KTSVG path="/media/icons/duotune/arrows/arr063.svg" className="svg-icon-3 me-1 m-0" />
    <span>Back </span>
  </>
);
export const DISCARD = "Discard";
export const CONTINUE = "Continue";
export const PLEASE_WAIT = (
  <>
    Please wait...<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
  </>
);

export const NO_RECORDS_FOUND = "No Records Found";

export const DISTRICT_CONST = "district";
export const TALUKA_CONST = "taluka";
export const FORUM_CONST = "forum";
export const JUDGE_CONST = "judge";
export const BANK_CONST = "banks";
export const DEPARTMENT_CONST = "department";
export const BANK_BRANCH_CONST = "bank-branch";
export const BANK_OFFICER_CONST = "bank-officer";
export const ADVOCATE_CONST = "advocate";
export const ASSOCIATE_ADVOCATE_CONST = "associate-advocate";
export const EXECUTER_CONST = "executer";
export const EXECUTIVE_OFFICER_DESIGNATION_CONST = "executive-officer-designation";
export const PRODUCTS_CONST = "product";
export const STAGE_CONST = "stage";
export const DESIGNATION_CONST = "designation";
export const OPPOSITE_ADVOCATE_CONST = "opposite-advocate";
export const DISPOSAL_CONST = "disposal";
export const CASE_TYPE_CONST = "case-type";
export const CASE_CATEGORY_CONST = "case-category";
