// Auth
export const LOGIN = "login";
export const RESET_PASSWORD = "reset-password";
export const FORGOT_PASSWORD = "forgot-password";

// Users
export const DASHBOARD = "/dashboard";
export const ADD_USER = "/case/add-user";
export const VIEW_USER = "/user/view-user";
export const EDIT_USER = '/user/edit-user';

// Masters
export const MASTERS = "/masters";
export const DISTRICT = "/masters/district";
export const TALUKA = "/masters/taluka";
export const FORUM = "/masters/forum";
export const JUDGE = "/masters/judge";
export const BANK = "/masters/banks";
export const DEPARTMENT = "/masters/department";
export const BANK_BRANCH = "/masters/bank-branch";
export const BANK_OFFICER = "/masters/bank-officer";
export const ADVOCATE = "/masters/advocate";
export const ASSOCIATE_ADVOCATE = "/masters/associate-advocate";
export const EXECUTER = "/masters/executer";
export const EXECUTIVE_OFFICER_DESIGNATION = "/masters/executive-officer-designation";
export const PRODUCTS = "/masters/product";
export const STAGE = "/masters/stage";
export const DESIGNATION = "/masters/designation";
export const OPPOSITE_ADVOCATE = "/masters/opposite-advocate";
export const DISPOSAL = "/masters/disposal";
export const CASE_TYPE = "/masters/case-type";
export const CASE_CATEGROY = "/masters/case-category";

// Add Masters
export const ADD_DISTRICT = "/masters/add-district";
export const ADD_TALUKA = "/masters/add-taluka";
export const ADD_FORUM = "/masters/add-forum";
export const ADD_JUDGE = "/masters/add-judge";
export const ADD_BANK = "/masters/add-bank-details";
export const ADD_DEPARTMENT = "/masters/add-department";
export const ADD_BANK_BRANCH = "/masters/add-bank-branch";
export const ADD_BANK_OFFICER = "/masters/add-bank-officer";
export const ADD_ADVOCATE = "/masters/add-advocate";
export const ADD_ASSOCIATE_ADVOCATE = "/masters/add-associate-advocate";
export const ADD_EXECUTER = "/masters/add-executer";
export const ADD_EXECUTIVE_OFFICER_DESIGNATION = "/masters/add-executive-officer-designation";

// Edit Masters
export const EDIT_DISTRICT = "/masters/edit-district";
export const EDIT_TALUKA = "/masters/edit-taluka";
export const EDIT_FORUM = "/masters/edit-forum";
export const EDIT_JUDGE = "/masters/edit-judge";
export const EDIT_BANK = "/masters/edit-bank-details";
export const EDIT_DEPARTMENT = "/masters/edit-department";
export const EDIT_BANK_BRANCH = "/masters/edit-bank-branch";
export const EDIT_BANK_OFFICER = "/masters/edit-bank-officer";
export const EDIT_ADVOCATE = "/masters/edit-advocate";
export const EDIT_ASSOCIATE_ADVOCATE = "/masters/edit-associate-advocate";
export const EDIT_EXECUTER = "/masters/edit-executer";
export const EDIT_EXECUTIVE_OFFICER_DESIGNATION = "/masters/edit-executive-officer-designation";

// Profile
export const PROFILE = "/profile";
export const EDIT_PROFILE = `${PROFILE}/edit`;

// Case
export const ADD_CASE = "/case/add-case";
export const EDIT_CASE = "/case/edit-case";
export const VIEW_CASE = "/case/view-case";
export const VIEW_CASE_CARD = '/case/view-case-card'
