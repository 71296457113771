// Login Details
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

//Reset Password
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

// Forgot Password
export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

// Change Password
export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

// Register User
export const REGISTER_USER_REQUEST = "REGISTER_USER_REQUEST";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAILURE = "REGISTER_USER_FAILURE";

// User Roles
export const USER_ROLE_REQUEST = "USER_ROLE_REQUEST";
export const USER_ROLE_SUCCESS = "USER_ROLE_SUCCESS";
export const USER_ROLE_FAILURE = "USER_ROLE_FAILURE";

// User List
export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAILURE = "USER_LIST_FAILURE";

// User Details
export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAILURE = "USER_DETAILS_FAILURE";

// User Details
export const USER_DETAILS_BY_ID_REQUEST = "USER_DETAILS_BY_ID_REQUEST";
export const USER_DETAILS_BY_ID_SUCCESS = "USER_DETAILS_BY_ID_SUCCESS";
export const USER_DETAILS_BY_ID_FAILURE = "USER_DETAILS_BY_ID_FAILURE";

// Add, Edit Details
export const EDIT_USER_DETAILS_REQUEST = "EDIT_USER_DETAILS_REQUEST";
export const EDIT_USER_DETAILS_SUCCESS = "EDIT_USER_DETAILS_SUCCESS";
export const EDIT_USER_DETAILS_FAILURE = "EDIT_USER_DETAILS_FAILURE";

// Activate-Deactivate User
export const ACTIVATE_DEACTIVATE_USER_REQUEST = "ACTIVATE_DEACTIVATE_USER_REQUEST";
export const ACTIVATE_DEACTIVATE_USER_SUCCESS = "ACTIVATE_DEACTIVATE_USER_SUCCESS";
export const ACTIVATE_DEACTIVATE_USER_FAILURE = "ACTIVATE_DEACTIVATE_USER_FAILURE";

// Masters
export const GET_ALL_DISTRICT_REQUEST = "GET_ALL_DISTRICT_REQUEST";
export const GET_ALL_DISTRICT_SUCCESS = "GET_ALL_DISTRICT_SUCCESS";
export const GET_ALL_DISTRICT_FAILURE = "GET_ALL_DISTRICT_FAILURE";

export const GET_ALL_DEPARTMENT_REQUEST = "GET_ALL_DEPARTMENT_REQUEST";
export const GET_ALL_DEPARTMENT_SUCCESS = "GET_ALL_DEPARTMENT_SUCCESS";
export const GET_ALL_DEPARTMENT_FAILURE = "GET_ALL_DEPARTMENT_FAILURE";

export const GET_ALL_TALUKA_REQUEST = "GET_ALL_TALUKA_REQUEST";
export const GET_ALL_TALUKA_SUCCESS = "GET_ALL_TALUKA_SUCCESS";
export const GET_ALL_TALUKA_FAILURE = "GET_ALL_TALUKA_FAILURE";

export const GET_ALL_FORUM_REQUEST = "GET_ALL_FORUM_REQUEST";
export const GET_ALL_FORUM_SUCCESS = "GET_ALL_FORUM_SUCCESS";
export const GET_ALL_FORUM_FAILURE = "GET_ALL_FORUM_FAILURE";

export const GET_ALL_BANK_REQUEST = "GET_ALL_BANK_REQUEST";
export const GET_ALL_BANK_SUCCESS = "GET_ALL_BANK_SUCCESS";
export const GET_ALL_BANK_FAILURE = "GET_ALL_BANK_FAILURE";

export const GET_ALL_BANK_BRANCH_REQUEST = "GET_ALL_BANK_BRANCH_REQUEST";
export const GET_ALL_BANK_BRANCH_SUCCESS = "GET_ALL_BANK_BRANCH_SUCCESS";
export const GET_ALL_BANK_BRANCH_FAILURE = "GET_ALL_BANK_BRANCH_FAILURE";

export const GET_ALL_BANK_OFFICER_REQUEST = "GET_ALL_BANK_OFFICER_REQUEST";
export const GET_ALL_BANK_OFFICER_SUCCESS = "GET_ALL_BANK_OFFICER_SUCCESS";
export const GET_ALL_BANK_OFFICER_FAILURE = "GET_ALL_BANK_OFFICER_FAILURE";

export const GET_ALL_ASSOCIATE_ADVOCATE_REQUEST = "GET_ASSOCIATE_ADVOCATE_REQUEST";
export const GET_ALL_ASSOCIATE_ADVOCATE_SUCCESS = "GET_ASSOCIATE_ADVOCATE_SUCCESS";
export const GET_ALL_ASSOCIATE_ADVOCATE_FAILURE = "GET_ASSOCIATE_ADVOCATE_FAILURE";

export const GET_ALL_EXECUTER_NAME_REQUEST = "GET_ALL_EXECUTER_NAME_REQUEST";
export const GET_ALL_EXECUTER_NAME_SUCCESS = "GET_ALL_EXECUTER_NAME_SUCCESS";
export const GET_ALL_EXECUTER_NAME_FAILURE = "GET_ALL_EXECUTER_NAME_FAILURE";

export const GET_ALL_EXECUTING_OFFICER_DESIGNATION_REQUEST = "GET_ALL_EXECUTING_OFFICER_DESIGNATION_REQUEST";
export const GET_ALL_EXECUTING_OFFICER_DESIGNATION_SUCCESS = "GET_ALL_EXECUTING_OFFICER_DESIGNATION_SUCCESS";
export const GET_ALL_EXECUTING_OFFICER_DESIGNATION_FAILURE = "GET_ALL_EXECUTING_OFFICER_DESIGNATION_FAILURE";

export const GET_ALL_JUDGE_NAME_REQUEST = "GET_ALL_JUDGE_NAME_REQUEST";
export const GET_ALL_JUDGE_NAME_SUCCESS = "GET_ALL_JUDGE_NAME_SUCCESS";
export const GET_ALL_JUDGE_NAME_FAILURE = "GET_ALL_JUDGE_NAME_FAILURE";

export const GET_ALL_OUR_ADVOCATE_REQUEST = "GET_ALL_OUR_ADVOCATE_REQUEST";
export const GET_ALL_OUR_ADVOCATE_SUCCESS = "GET_ALL_OUR_ADVOCATE_SUCCESS";
export const GET_ALL_OUR_ADVOCATE_FAILURE = "GET_ALL_OUR_ADVOCATE_FAILURE";

export const GET_ALL_PRODUCT_REQUEST = "GET_ALL_PRODUCT_REQUEST";
export const GET_ALL_PRODUCT_SUCCESS = "GET_ALL_PRODUCT_SUCCESS";
export const GET_ALL_PRODUCT_FAILURE = "GET_ALL_PRODUCT_FAILURE";

export const GET_ALL_STAGE_REQUEST = "GET_ALL_STAGE_REQUEST";
export const GET_ALL_STAGE_SUCCESS = "GET_ALL_STAGE_SUCCESS";
export const GET_ALL_STAGE_FAILURE = "GET_ALL_STAGE_FAILURE";

export const GET_ALL_DISPOSAL_REQUEST = "GET_ALL_DISPOSAL_REQUEST";
export const GET_ALL_DISPOSAL_SUCCESS = "GET_ALL_DISPOSAL_SUCCESS";
export const GET_ALL_DISPOSAL_FAILURE = "GET_ALL_DISPOSAL_FAILURE";

export const GET_ALL_DESIGNATION_REQUEST = "GET_ALL_DESIGNATION_REQUEST";
export const GET_ALL_DESIGNATION_SUCCESS = "GET_ALL_DESIGNATION_SUCCESS";
export const GET_ALL_DESIGNATION_FAILURE = "GET_ALL_DESIGNATION_FAILURE";

export const GET_ALL_CASE_TYPE_REQUEST = "GET_ALL_CASE_TYPE_REQUEST";
export const GET_ALL_CASE_TYPE_SUCCESS = "GET_ALL_CASE_TYPE_SUCCESS";
export const GET_ALL_CASE_TYPE_FAILURE = "GET_ALL_CASE_TYPE_FAILURE";

export const GET_ALL_CASE_CATEGORY_REQUEST = "GET_ALL_CASE_CATEGORY_REQUEST";
export const GET_ALL_CASE_CATEGORY_SUCCESS = "GET_ALL_CASE_CATEGORY_SUCCESS";
export const GET_ALL_CASE_CATEGORY_FAILURE = "GET_ALL_CASE_CATEGORY_FAILURE";

export const GET_ALL_OPPSITE_ADVOCATE_REQUEST = "GET_ALL_OPPSITE_ADVOCATE_REQUEST";
export const GET_ALL_OPPSITE_ADVOCATE_SUCCESS = "GET_ALL_OPPSITE_ADVOCATE_SUCCESS";
export const GET_ALL_OPPSITE_ADVOCATE_FAILURE = "GET_ALL_OPPSITE_ADVOCATE_FAILURE";

// Post
export const POST_DISTRICT_REQUEST = "POST_DISTRICT_REQUEST";
export const POST_DISTRICT_SUCCESS = "POST_DISTRICT_SUCCESS";
export const POST_DISTRICT_FAILURE = "POST_DISTRICT_FAILURE";

export const POST_TALUKA_REQUEST = "POST_TALUKA_REQUEST";
export const POST_TALUKA_SUCCESS = "POST_TALUKA_SUCCESS";
export const POST_TALUKA_FAILURE = "POST_TALUKA_FAILURE";

export const POST_FORUM_REQUEST = "POST_FORUM_REQUEST";
export const POST_FORUM_SUCCESS = "POST_FORUM_SUCCESS";
export const POST_FORUM_FAILURE = "POST_FORUM_FAILURE";

export const POST_JUGDE_REQUEST = "POST_JUDGE_REQUEST";
export const POST_JUGDE_SUCCESS = "POST_JUDGE_SUCCESS";
export const POST_JUGDE_FAILURE = "POST_JUDGE_FAILURE";

export const POST_BANK_REQUEST = "POST_BANK_REQUEST";
export const POST_BANK_SUCCESS = "POST_BANK_SUCCESS";
export const POST_BANK_FAILURE = "POST_BANK_FAILURE";

export const POST_DEPARTMENT_REQUEST = "POST_DEPARTMENT_REQUEST";
export const POST_DEPARTMENT_SUCCESS = "POST_DEPARTMENT_SUCCESS";
export const POST_DEPARTMENT_FAILURE = "POST_DEPARTMENT_FAILURE";

export const POST_BANK_BRANCH_REQUEST = "POST_BANK_BRANCH_REQUEST";
export const POST_BANK_BRANCH_SUCCESS = "POST_BANK_BRANCH_SUCCESS";
export const POST_BANK_BRANCH_FAILURE = "POST_BANK_BRANCH_FAILURE";

export const POST_BANK_OFFICER_REQUEST = "POST_BANK_OFFICER_REQUEST";
export const POST_BANK_OFFICER_SUCCESS = "POST_BANK_OFFICER_SUCCESS";
export const POST_BANK_OFFICER_FAILURE = "POST_BANK_OFFICER_FAILURE";

export const POST_ADVOCATE_REQUEST = "POST_ADVOCATE_REQUEST";
export const POST_ADVOCATE_SUCCESS = "POST_ADVOCATE_SUCCESS";
export const POST_ADVOCATE_FAILURE = "POST_ADVOCATE_FAILURE";

export const POST_ASSOCIATE_ADVOCATE_REQUEST = "POST_ASSOCIATE_ADVOCATE_REQUEST";
export const POST_ASSOCIATE_ADVOCATE_SUCCESS = "POST_ASSOCIATE_ADVOCATE_SUCCESS";
export const POST_ASSOCIATE_ADVOCATE_FAILURE = "POST_ASSOCIATE_ADVOCATE_FAILURE";

export const POST_EXECUTER_REQUEST = "POST_EXECUTER_REQUEST";
export const POST_EXECUTER_SUCCESS = "POST_EXECUTER_SUCCESS";
export const POST_EXECUTER_FAILURE = "POST_EXECUTER_FAILURE";

export const POST_EXECUTING_OFFICER_DESIGNATION_REQUEST = "POST_EXECUTING_OFFICER_DESIGNATION_REQUEST";
export const POST_EXECUTING_OFFICER_DESIGNATION_SUCCESS = "POST_EXECUTING_OFFICER_DESIGNATION_SUCCESS";
export const POST_EXECUTING_OFFICER_DESIGNATION_FAILURE = "POST_EXECUTING_OFFICER_DESIGNATION_FAILURE";

export const POST_MASTER_DATA_REQUEST = "POST_MASTER_DATA_REQUEST";
export const POST_MASTER_DATA_SUCCESS = "POST_MASTER_DATA_SUCCESS";
export const POST_MASTER_DATA_FAILURE = "POST_MASTER_DATA_FAILURE";

export const ACTIVE_DEACTIVE_MASTERS_REQUEST = "ACTIVE_DEACTIVE_MASTERS_REQUEST";
export const ACTIVE_DEACTIVE_MASTERS_SUCCESS = "ACTIVE_DEACTIVE_MASTERS_SUCCESS";
export const ACTIVE_DEACTIVE_MASTERS_FAILURE = "ACTIVE_DEACTIVE_MASTERS_FAILURE";

export const GET_BY_ID_MASTERS_REQUEST = "GET_BY_ID_MASTERS_REQUEST";
export const GET_BY_ID_MASTERS_SUCCESS = "GET_BY_ID_MASTERS_SUCCESS";
export const GET_BY_ID_MASTERS_FAILURE = "GET_BY_ID_MASTERS_FAILURE";

export const UPDATE_MASTERS_REQUEST = "UPDATE_MASTERS_REQUEST";
export const UPDATE_MASTERS_SUCCESS = "UPDATE_MASTERS_SUCCESS";
export const UPDATE_MASTERS_FAILURE = "UPDATE_MASTERS_FAILURE";

// DropDown
export const GET_BANK_BRANCH_BY_BANK_ID_REQUEST = "GET_BANK_BRANCH_BY_BANK_ID_REQUEST";
export const GET_BANK_BRANCH_BY_BANK_ID_SUCCESS = "GET_BANK_BRANCH_BY_BANK_ID_SUCCESS";
export const GET_BANK_BRANCH_BY_BANK_ID_FAILURE = "GET_BANK_BRANCH_BY_BANK_ID_FAILURE";

export const GET_TALUKA_BY_DISTRICT_ID_REQUEST = "GET_TALUKA_BY_DISTRICT_ID_REQUEST";
export const GET_TALUKA_BY_DISTRICT_ID_SUCCESS = "GET_TALUKA_BY_DISTRICT_ID_SUCCESS";
export const GET_TALUKA_BY_DISTRICT_ID_FAILURE = "GET_TALUKA_BY_DISTRICT_ID_FAILURE";


export const GET_DISTRICT_FOR_DROPDOWN_MASTERS_REQUEST = "GET_DISTRICT_FOR_DROPDOWN_MASTERS_REQUEST";
export const GET_DISTRICT_FOR_DROPDOWN_MASTERS_SUCCESS = "GET_DISTRICT_FOR_DROPDOWN_MASTERS_SUCCESS";
export const GET_DISTRICT_FOR_DROPDOWN_MASTERS_FAILURE = "GET_DISTRICT_FOR_DROPDOWN_MASTERS_FAILURE";

export const GET_BANK_FOR_DROPDOWN_MASTERS_REQUEST = "GET_BANK_FOR_DROPDOWN_MASTERS_REQUEST";
export const GET_BANK_FOR_DROPDOWN_MASTERS_SUCCESS = "GET_BANK_FOR_DROPDOWN_MASTERS_SUCCESS";
export const GET_BANK_FOR_DROPDOWN_MASTERS_FAILURE = "GET_BANK_FOR_DROPDOWN_MASTERS_FAILURE";



export const GET_FORUM_DROPDOWN_MASTERS_REQUEST = "GET_FORUM_FOR_DROPDOWN_MASTERS_REQUEST";
export const GET_FORUM_DROPDOWN_MASTERS_SUCCESS = "GET_FORUM_FOR_DROPDOWN_MASTERS_SUCCESS";
export const GET_FORUM_DROPDOWN_MASTERS_FAILURE = "GET_FORUM_FOR_DROPDOWN_MASTERS_FAILURE";

export const GET_JUDGE_DROPDOWN_MASTERS_REQUEST = "GET_JUDGE_FOR_DROPDOWN_MASTERS_REQUEST";
export const GET_JUDGE_DROPDOWN_MASTERS_SUCCESS = "GET_JUDGE_FOR_DROPDOWN_MASTERS_SUCCESS";
export const GET_JUDGE_DROPDOWN_MASTERS_FAILURE = "GET_JUDGE_FOR_DROPDOWN_MASTERS_FAILURE";

export const GET_STAGE_FOR_DROPDOWN_MASTERS_REQUEST = "GET_STAGE_FOR_DROPDOWN_MASTERS_REQUEST";
export const GET_STAGE_FOR_DROPDOWN_MASTERS_SUCCESS = "GET_STAGE_FOR_DROPDOWN_MASTERS_SUCCESS";
export const GET_STAGE_FOR_DROPDOWN_MASTERS_FAILURE = "GET_STAGE_FOR_DROPDOWN_MASTERS_FAILURE";

export const GET_PRODUCT_FOR_DROPDOWN_MASTERS_REQUEST = "GET_PRODUCT_FOR_DROPDOWN_MASTERS_REQUEST";
export const GET_PRODUCT_FOR_DROPDOWN_MASTERS_SUCCESS = "GET_PRODUCT_FOR_DROPDOWN_MASTERS_SUCCESS";
export const GET_PRODUCT_FOR_DROPDOWN_MASTERS_FAILURE = "GET_PRODUCT_FOR_DROPDOWN_MASTERS_FAILURE";

export const GET_DESIGNATION_FOR_DROPDOWN_REQUEST = "GET_DESIGNATION_FOR_DROPDOWN_REQUEST";
export const GET_DESIGNATION_FOR_DROPDOWN_SUCCESS = "GET_DESIGNATION_FOR_DROPDOWN_SUCCESS";
export const GET_DESIGNATION_FOR_DROPDOWN_FAILURE = "GET_DESIGNATION_FOR_DROPDOWN_FAILURE";

export const GET_CASE_CAT_FOR_DROPDOWN_REQUEST = "GET_CASE_CAT_FOR_DROPDOWN_REQUEST";
export const GET_CASE_CAT_FOR_DROPDOWN_SUCCESS = "GET_CASE_CAT_FOR_DROPDOWN_SUCCESS";
export const GET_CASE_CAT_FOR_DROPDOWN_FAILURE = "GET_CASE_CAT_FOR_DROPDOWN_FAILURE";

export const GET_EXE_OFF_DESIGNATION_FOR_DROPDOWN_REQUEST = "GET_EXE_OFF_DESIGNATION_FOR_DROPDOWN_REQUEST";
export const GET_EXE_OFF_DESIGNATION_FOR_DROPDOWN_SUCCESS = "GET_EXE_OFF_DESIGNATION_FOR_DROPDOWN_SUCCESS";
export const GET_EXE_OFF_DESIGNATION_FOR_DROPDOWN_FAILURE = "GET_EXE_OFF_DESIGNATION_FOR_DROPDOWN_FAILURE";

export const GET_BANK_OFFICER_FOR_DROPDOWN_MASTERS_REQUEST = "GET_BANK_OFFICER_FOR_DROPDOWN_MASTERS_REQUEST";
export const GET_BANK_OFFICER_FOR_DROPDOWN_MASTERS_SUCCESS = "GET_BANK_OFFICER_FOR_DROPDOWN_MASTERS_SUCCESS";
export const GET_BANK_OFFICER_FOR_DROPDOWN_MASTERS_FAILURE = "GET_BANK_OFFICER_FOR_DROPDOWN_MASTERS_FAILURE";

export const GET_BANK_OFFICER_BY_BANK_REQUEST = "GET_BANK_OFFICER_BY_BANK_REQUEST";
export const GET_BANK_OFFICER_BY_BANK_SUCCESS = "GET_BANK_OFFICER_BY_BANK_SUCCESS";
export const GET_BANK_OFFICER_BY_BANK_FAILURE = "GET_BANK_OFFICER_BY_BANK_FAILURE";

export const GET_JUDGE_BY_TALUKA_REQUEST = "GET_JUDGE_BY_TALUKA_REQUEST";
export const GET_JUDGE_BY_TALUKA_SUCCESS = "GET_JUDGE_BY_TALUKA_SUCCESS";
export const GET_JUDGE_BY_TALUKA_FAILURE = "GET_JUDGE_BY_TALUKA_FAILURE";

export const GET_CASE_TYPE_BY_CASE_CAT_REQUEST = "GET_CASE_TYPE_BY_CASE_CAT_REQUEST";
export const GET_CASE_TYPE_BY_CASE_CAT_SUCCESS = "GET_CASE_TYPE_BY_CASE_CAT_SUCCESS";
export const GET_CASE_TYPE_BY_CASE_CAT_FAILURE = "GET_CASE_TYPE_BY_CASE_CAT_FAILURE";


// Case
export const ADD_CASE_REQUEST = "ADD_CASE_REQUEST";
export const ADD_CASE_SUCCESS = "ADD_CASE_SUCCESS";
export const ADD_CASE_FAILURE = "ADD_CASE_FAILURE";

export const EDIT_CASE_REQUEST = "EDIT_CASE_REQUEST";
export const EDIT_CASE_SUCCESS = "EDIT_CASE_SUCCESS";
export const EDIT_CASE_FAILURE = "EDIT_CASE_FAILURE";

export const GET_CASE_BY_ID_REQUEST = "GET_CASE_BY_ID_REQUEST";
export const GET_CASE_BY_ID_SUCCESS = "GET_CASE_BY_ID_SUCCESS";
export const GET_CASE_BY_ID_FAILURE = "GET_CASE_BY_ID_FAILURE";

export const GET_ALL_CASE_REQUEST = "GET_ALL_CASE_REQUEST";
export const GET_ALL_CASE_SUCCESS = "GET_ALL_CASE_SUCCESS";
export const GET_ALL_CASE_FAILURE = "GET_ALL_CASE_FAILURE";

export const ACTIVE_DEACTIVE_CASE_REQUEST = "ACTIVE_DEACTIVE_CASE_REQUEST";
export const ACTIVE_DEACTIVE_CASE_SUCCESS = "ACTIVE_DEACTIVE_CASE_SUCCESS";
export const ACTIVE_DEACTIVE_CASE_FAILURE = "ACTIVE_DEACTIVE_CASE_FAILURE";
